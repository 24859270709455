import React from 'react';
import auto_before from './assets/images/auto_before.png';
import auto_after from './assets/images/auto_after.png';

export class Form2 extends React.Component {

    constructor(props){
        super(props);

        this.state = {};

        this.nextStep = this.nextStep.bind(this);
    }

    nextStep(event){
        if(event.target.className.includes('continue')){
            window.open('https://winfulencies.com/f9757084-ec4e-4696-8a6e-38276133b686?address=&city=&state=&zip=&email=&first_name=&last_name=&phone=&clickid', '_blank');
        }
        this.props.changeProgressBar('65%')
        this.props.changeView(this.props.position)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render (){
        return (
            <form id="form2" >

            <p><b className="text-big">You can get $81.51 off your monthly auto insurance payments</b></p>
            <p className="text-big"> If you are currently insured, drive less than 50 miles/day and live in a qualified zip code you can get an extremely high discount.</p>


            <div id="obamacare">
                <img src={auto_after} width="100%" />
            </div>


    
                <button type="button" onClick={this.nextStep} className="text-center continue-button">
                    Claim Your Discount<i className="fa fa-spinner fa-spin"></i>
                </button>
                <div className="text-center skip-button" onClick={this.nextStep}>Skip</div>

            
        </form>

        )
    }

}

export default Form2;