import React from 'react';
import obamacare_card from './assets/images/obamacare-card.png'

export class Form2 extends React.Component {

    constructor(props){
        super(props);

        this.state = {};

        this.nextStep = this.nextStep.bind(this);
    }

    nextStep(event){
        if(event.target.className.includes('continue')){
            window.open('https://quotes.healthcare.com/aca-enroll-2', '_blank');
        }
        this.props.changeProgressBar('60%')
        this.props.changeView(this.props.position)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render (){
        return (
            <form id="form2" >
                <img src="https://winfulencies.com/conversion.gif?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL" width="1" height="1"/>
            <p><b className="text-big">Supreme Court Upholds Obamacare: Americans may qualify for $0/Month Health Plans.</b></p>
            <p className="text-big">Enroll in Affordable Care Act Plans in Florida</p>


            <div id="obamacare">
                <img src={obamacare_card} height="100%" width="100%" />
            </div>


    
                <button type="button" onClick={this.nextStep} className="text-center continue-button">
                    Enroll Now<i className="fa fa-spinner fa-spin"></i>
                </button>
                <div className="text-center skip-button" onClick={this.nextStep}>Skip</div>

            
        </form>

        )
    }

}

export default Form2;