import React from 'react';
import freephone from './assets/images/freephone.png';

export class Form2b extends React.Component {

    constructor(props){
        super(props);
        this.state = {};
    
        this.nextStep = this.nextStep.bind(this);
    }

    nextStep(event){
        if(event.target.className.includes('continue')){
            window.open('https://qlinkwireless.com/signup/m-06-22/', '_blank');
        }
        this.props.changeProgressBar('60%')
        this.props.changeView(this.props.position)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render (){
        return (
            <form id="form2b" >
            <p><b className="text-big">Government Benefit Program: FREE Phone Plans</b></p>
            <p>This free government-sponsored program includes Unlimited talk, text, and free high-speed data.</p>


            <div id="obamacare">
                <img src={freephone} height="100%" width="100%" />
            </div>


    
                <button type="button" onClick={this.nextStep} className="text-center continue-button">
                    Claim Your Phone<i className="fa fa-spinner fa-spin"></i>
                </button>
                <div className="text-center skip-button" onClick={this.nextStep}>Skip</div>

            
        </form>

        )
    }

}

export default Form2b;