import React from "react";
import ReactDOMClient from 'react-dom/client';
import Form3 from './Form3.js';
import email_with_money from './assets/images/email_with_money.svg';


class Form4 extends React.Component {


    render() {
        return (
            <form id="form4">
            <p className="text-bold text-big">Get $500 deposited into your chosen account.</p>

            
            <a href="https://flashrewards.co/?Flow=6F6EA60F-B353-1F1A-926A-32674E451065382AB6B5&reward=paypal750">
                <div className="receive-option d-flex" data-key="1">
                {/* <img src="assets/images/paypal.svg" alt="" height="18.38"/> */}
                <span>Pay Pal</span>
            
                <img src="assets/images/input_valid.svg" className="opacity-0" alt=""/>
            </div>
            </a>

            <a href="https://flashrewards.co/?Flow=6F6EA60F-B353-1F1A-926A-32674E451065382AB6B5&reward=cashapp750v3&subaff1=flashcash.com+b&subaff2=207043&subaff3=c&subaff4=cashapp750v3&subaff5=634264899995&gclid=CjwKCAjw0ZiiBhBKEiwA4PT9zwjzcDU5sFV__woiZclNteEm2j09j02o8A2QjCMY6PWtrw4HuHJajBoCkhQQAvD_BwE">
            <div className="receive-option d-flex" data-key="2">
                {/* <img src="assets/images/cash_app.svg" height="18" alt=""/> */}
                <span>Cash App</span>
                <img src="assets/images/input_valid.svg" className="opacity-0" alt=""/>
            </div>
            </a>
        </form>
        
        )
    }
}

export default Form4;