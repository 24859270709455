import React from "react";
import email_with_money from './assets/images/email_with_money.svg';


class Form1 extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value: '',
            phone: '',
            name: ''    
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.continueWithoutEmail = this.continueWithoutEmail.bind(this);
    
    }

    componentDidMount(){
        const urlParams = new URLSearchParams(document.location.search)
        fetch('https://lobster-app-zrgrk.ondigitalocean.app/prepop/leadid/'+urlParams.get('leadid'))
        .then(response => {
        console.log('Response: '+response)
        return response.json();
        })
        .then(responseJSON => {
            this.setState({...responseJSON, value: responseJSON.email});
        })
        .catch((err) => {
            console.log('Didn\'t fetch: '+err);
        })
    }
    


    handleSubmit(event){
        event.preventDefault();

        fetch('https://lobster-app-zrgrk.ondigitalocean.app/insert?email='+event.target[0].value+'&first_name='+this.state.first_name+'&last_name='+this.state.last_name)
        .then(response => {
            console.log(response)
        })
        .catch(err => {
            console.log('Error: '+err)
        })

        this.props.onSubmit(this.state)
        this.props.changeProgressBar('50%');
        this.props.changeView(this.props.position);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    //     const formDiv = document.getElementById('formDiv');
    //     const root = ReactDOMClient.createRoot(formDiv)
    //    root.render(<Form3 />);
    }

    handleChange(event){
        if(event.target.name === "phone"){
            if (/^\d*$/.test(event.target.value)) {
                this.setState({
                    value: event.target.value,
                    [event.target.name]: event.target.value
                });
            }
        } else {
        this.setState({
            value: event.target.value,
            [event.target.name]: event.target.value
            });
        }
    }

    continueWithoutEmail(event){
        event.preventDefault();

        this.props.onSubmit(this.state)
        this.props.changeProgressBar('50%');
        this.props.changeView(this.props.position);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        const emailConsent = {
            fontSize: "10px",
            lineHeight: "10px"

        }

        const iconGroupStyle = {
            display: "flex",
            alignItems: "center",
            gap: "5px"
        }

        return (
            
            
        <form id="form1" onSubmit={this.handleSubmit}>
            <p>You can Save </p>
            <p>$100</p> 
            <p>On your Monthly Bills</p>
            <div className="d-flex justify-content-round align-items-center">
                <img width="196" height="173" src={email_with_money} alt=""/>
            </div>
            <div className="divider"></div>
            <div className="input-group">
            <div className="icon-group" style={iconGroupStyle}>
                <i className="fas fa-user"></i>
                <input id="input-name" type="name" name="name" value={this.state.name} onChange={this.handleChange} placeholder="Full Name" required />
            </div>
            <div className="icon-group" style={iconGroupStyle}>
            <i className="fas fa-phone"></i>
                <input type="text" name="phone" value={this.state.phone} id="input-phone" onChange={this.handleChange} placeholder="Phone" maxLength="10" minLength="10" required />
            </div>
            </div>
            <p style={emailConsent}>By clicking “Continue” I provide my signature consenting to contact from BillKrunch at the number I provided regarding products or services via live, automated, or pre recorded telephone call or text even if that number is on any local, state, or national “do not call” list. I understand that my telephone company may impose charges on me for these contacts, and I am not required to enter into this agreement as a condition of any purchase. You may opt-out at any time by replying STOP. Reply HELP for help. Max 10 msgs/mo. I also agree to the <a href="https://monthlyaid.org/terms/" target="_blank">Terms & Conditions</a> and <a href="https://monthlyaid.org/privacy/" target="_blank">Privacy Policy</a>.</p>
            <br/><button type="submit" className="form1Button">Continue<i className="fa fa-spinner fa-spin"></i></button>
        </form>
        
        )
    }
}

export default Form1;