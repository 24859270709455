import './App.css';
import Form1 from './Form1.js';
import ProgressBar from './ProgressBar';
import React from 'react';
import Form3 from './Form3.js';
import Form4 from './Form4.js';
import Form2 from './Form2.js';
import Form2b from './Form2b.js';
import Form2c from './Form2c.js';
import Info from './Info.js';
import Footer from './Footer.js';
import AutoInsurance from './AutoInsurance.js';

import logo from './assets/images/logo.png';


class App extends React.Component {

  constructor(props){
    super(props);
    

    this.state = {progress: '25%', view: 0,
  userInfo: {}}

    this.changeView = this.changeView.bind(this);
    this.onSubmitForm1 = this.onSubmitForm1.bind(this);

    
  }

  componentDidMount(){
    
  }

  sendUserInfo(){
    if(this.state.userInfo){
      return this.state.userInfo;
    } else {
      return "";
    }
}

  onSubmitForm1(stateForm1){
    this.setState({userInfo: stateForm1});
  }

  

  changeProgressBar = (progress) => {
    this.setState({progress: progress});
  }

  changeView(param){
    console.log(param)
    this.setState({view: param+1})
  }

  renderSwitch(param){

    const  formArray = [
  <Form1 changeProgressBar={this.changeProgressBar} changeView={this.changeView} onSubmit={this.onSubmitForm1} position={this.state.view} />,
  <Form2 changeProgressBar={this.changeProgressBar} changeView={this.changeView} userInfo={this.state.userInfo} position={this.state.view} />,
  <Form2b changeProgressBar={this.changeProgressBar} changeView={this.changeView} userInfo={this.state.userInfo} position={this.state.view} />,
  <Form2c changeProgressBar={this.changeProgressBar} changeView={this.changeView} userInfo={this.state.userInfo} position={this.state.view} />,
  <Form4 changeProgressBar={this.changeProgressBar} changeView={this.changeView} />
]
    
    return formArray[param];

  }

  render() {
    return (
      <div>
    <div className="App" id='App'>
      <main>
        <img src={logo} />
        <ProgressBar progress={this.state.progress} />
      <div id="formDiv">
        {this.renderSwitch(this.state.view)}
      </div>
      </main>
    </div>
    <Info />
    <Footer />
    </div>
  )}
}


export default App;
