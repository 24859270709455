import React from 'react';
import holdingcheck from './assets/images/holdingcheck.jpg';

export class Form2b extends React.Component {

    constructor(props){
        super(props);
        this.state = {};
    
        this.nextStep = this.nextStep.bind(this);
    }

    nextStep(event){
        if(event.target.className.includes('continue')){
            window.open('https://www.disability-benefits-help.org/cpc/general', '_blank');
        }
        this.props.changeProgressBar('60%')
        this.props.changeView(this.props.position)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render (){
        return (
            <form id="form2b" >
            <p><b className="text-big">You could be entitled to $3,627 per month!</b></p>
            <p>Free diisability evaluation</p>


            <div id="obamacare">
                <img src={holdingcheck} height="100%" width="100%" />
            </div>


    
                <button type="button" onClick={this.nextStep} className="text-center continue-button">
                    Submit Your Claim<i className="fa fa-spinner fa-spin"></i>
                </button>
                <div className="text-center skip-button" onClick={this.nextStep}>Skip</div>

            
        </form>

        )
    }

}

export default Form2b;