import { findByLabelText } from "@testing-library/react";
import React from "react";
import ReactDOMClient from 'react-dom/client';

class Info extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render (){

        const infoImg = {
            maxWidth: "100px",
            maxHeight: "100px",
            margin: "25px"
        }

        const infoContainer = {
            minHeight: "620px",
            background: "#cbd5fb",
            paddingBottom: "100px"
        }

        const infoDiv = {
            margin: "0 auto",
            maxWidth: "600px",
            padding: "10px"
            
        }
        
        const infoBoxContainer = {
            marginTop: "50px",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap"
        }

        

        const infoHeader = {
            textAlign: "left"
        }

        return (
            <div style={infoContainer}>
                <div style={{fill: "#6565E2"}}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div style={infoDiv}>
                <p style={infoHeader} class="infoHeader"><b>Most Americans Are Overpaying On Their Monthly Bills</b></p><br/>
                <p style={{textAlign: "left"}}>Did you know the average American is over paying on their monthly bills by up to $100?</p>
                </div>
                <div style={infoBoxContainer}>
                <div className="infoBox"><img src={require("./assets/images/cashback_green.png")} style={infoImg} />Bill Refunds</div>
                <div className="infoBox"><img src={require("./assets/images/health-insurance.png")} style={infoImg} />$0/Month Health Insurance coverage</div>
                <div className="infoBox"><img src={require("./assets/images/phone-service.png")} style={infoImg} />Government Sponsored Phone Plans</div>
                <div className="infoBox"><img src={require("./assets/images/injury-money.png")} style={infoImg} />Injury Compensation</div>
                </div>
            </div>
        )
    }
}

export default Info;