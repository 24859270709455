import React from "react";
import ReactDOMClient from 'react-dom/client';

import ad_bullet from "./assets/images/ad_bullet.svg";
import ad_yes from './assets/images/ad_yes.svg';
import ad_no from './assets/images/ad_no.svg'
import ad_thumbnail from "./assets/images/ad_thumbnail.png";
import burning_dollar from "./assets/images/burning_dollar.png";
import ad_thumbnail_ad3 from "./assets/images/ad_thumbnail_ad3.png";


class Form3 extends React.Component {

    constructor(props){
        super(props);
        this.state = {questionsAnswered: 0}
        // this.changeProgressBar();
        this.answer = this.answer.bind(this);
        this.nextStep = this.nextStep.bind(this);
        
        }
    
    answer(event){
        
        this.setState({questionsAnswered: this.state.questionsAnswered+1}, () => {
            if(this.state.questionsAnswered === 3) {
                this.nextStep()
            }
        });
        const adId = document.querySelector('[adId="'+event.currentTarget.attributes.data.value+'"]');
        const root = ReactDOMClient.createRoot(adId);
        root.render(null)

        
        //rootAnswerDiv.render('')
    }

    handleContinue(){
        this.props.changeProgressBar('100%')
        this.props.changeView(this.props.position)
    }

    nextStep(){
        this.props.changeProgressBar('100%')
        this.props.changeView(this.props.position)
    }

    // changeProgressBar(){
    //     console.log(document.getElementById('red'));
    // }
    render(){
    return (
        
        <form id="form3">

            <p><b className="text-big">Great News, {this.props.userInfo.first_name}</b></p>
            <p className="text-big">These aid programs are available to assist with your monthly bills</p>

            <div adid="1">
            <div className="ad" data-key="1">
                <div className="d-flex">
                    <img src={ad_thumbnail_ad3} width="66" height="66" alt=""/>
                    <div>
                        <p><b>Apply for Rental Assistance</b></p>
                        <p><b className="text-red">Average amount claimed - $1000</b></p>
                        <div className="d-flex justify-content-left dollar-signs">
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="bullets">
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>OVER $25 BILLION AVAILABLE IN RENTAL ASSISTANCE</p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Emergency rent relief</p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>COVID 19 Relief</p>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="d-flex justify-content-center align-items-center buttons">
                    <a id="ad3-link" href={`https://winfulencies.com/e2e9c25a-c68b-44e6-ae0a-330a877285c2?email=${this.props.userInfo.email}&first_name=${this.props.userInfo.first_name}&last_name=${this.props.userInfo.last_name}&clickid=`} target="_blank">
                        <button type="button" className="yes" data="1" onClick={this.answer}><span><img src={ad_yes}
                                                                                  alt=""/></span>YES
                        </button>
                    </a>
                    <button type="button" className="no" data="1" onClick={this.answer}><span><img src={ad_no}
                                                                             alt=""/></span>No
                    </button>
                </div>
                <label className="error d-none" htmlFor="ad1"></label>
                <input type="hidden" name="ad1" />
                
            </div>
            </div>




            <div adid="3">
            <div className="ad" data-key="3" id="ad-3">
                <div className="d-flex">
                    <img src={burning_dollar} width="66" height="66" alt=""/>
                    <div>
                        <p><b>Claim Your Inflation Relief</b></p>
                        <div className="d-flex justify-content-left dollar-signs">
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="bullets">
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Non-repayable Grants </p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Public and Private Programs</p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Food and Gas Relief</p>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="d-flex justify-content-center align-items-center buttons">
                    <a id="ad1-link" href={`https://winfulencies.com/232e514c-e3bd-4ca0-bf89-aa6b9aa6890d?email=${this.props.userInfo.email}&first_name=${this.props.userInfo.first_name}&last_name=${this.props.userInfo.last_name}&clickid=`} target="_blank">
                        <button type="button" className="yes" data="3" onClick={this.answer}><span><img src={ad_yes}
                                                                                  alt=""/></span>YES
                        </button>
                    </a>
                    <button type="button" className="no" data="3" onClick={this.answer}><span><img src={ad_no}
                                                                             alt=""/></span>No
                    </button>
                </div>
                <label className="error d-none" htmlFor="ad3"></label>
                <input type="hidden" name="ad3" />
            </div>
            </div>




            <div adid="2">
            <div className="ad" data-key="2">
                <div className="d-flex">
                    <img src={ad_thumbnail} width="66" height="66" alt=""/>
                    <div>
                        <p><b>Utility Expense Relief</b></p>
                        <p><b className="text-red">Average amount claimed - $1000</b></p>
                        <div className="d-flex justify-content-left dollar-signs">
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                            <span>$</span>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="bullets">
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Energy bill relief.</p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Gas discounts.</p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="bullet">
                        <img src={ad_bullet} alt=""/>
                        <p>Reduced heating costs.</p>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="d-flex justify-content-center align-items-center buttons">
                    <a id="ad2-link" href={`https://winfulencies.com/5bf6eba9-a4ab-45b0-9c6d-86970acf811c?email=${this.props.userInfo.email}&first_name=${this.props.userInfo.first_name}&last_name=${this.props.userInfo.last_name}&clickid=`} target="_blank">
                    <button type="button" className="yes" data="2" onClick={this.answer}><span><img src={ad_yes}
                                                                                  alt=""/></span>YES
                        </button>
                    </a>
                    <button type="button" className="no" data="2" onClick={this.answer}><span><img src={ad_no}
                                                                             alt=""/></span>No
                    </button>
                </div>
                <label className="error d-none" htmlFor="ad2"></label>
                <input type="hidden" name="ad2" />
            </div>
            </div>

        

            

            
            


    
            <button type="button" className="text-center" onClick={this.nextStep}>Continue<i className="fa fa-spinner fa-spin"></i></button>
        </form>
    )
    }
}

export default Form3;