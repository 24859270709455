import { findByLabelText } from "@testing-library/react";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import React from "react";
import ReactDOMClient from 'react-dom/client';

class Info extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render (){
        const footer = {
            background: "#2e2e2e",
            fontSize: "12px",
            color: "white",
            padding: "10px"
        }
        return (
            <div style={footer}>
                THIS SITE IS NOT AFFILIATED WITH ANY GOVERNMENT AGENCY.<br/><br/>

                <a href="https://monthlyaid.org/privacy/" target="_blank" style={{color: "white"}}>Privacy Policy</a> | <a href="https://monthlyaid.org/terms/" target="_blank" style={{color: "white"}}>Terms & Conditions</a><br/><br/>
                MonthlyAid and other offers (collectively “Offers”) change often. We cannot guarantee that you will receive any of the Offers that you request, as Offers are subject to availability and are often made by other entities. Offers distributed by MonthlyAid are limited in quantity, and MonthlyAid reserves the right to substitute Offers based on availability. Offers that are not distributed by MonthlyAid are monitored by other entities, and we are not responsible if such Offers are no longer available, change, if you do not receive the Offers, if you do not qualify for the Offers, or if any other issues arise. The third-party product names, logos, brands, and trademarks shown on this website are the property of their respective owners. These companies are not affiliated with MonthlyAid. These third parties do not sponsor or endorse this website, its content or services.
                <br/><br/>© 2022-2023 MonthlyAid.com
            </div>
        )
    }
}

export default Info;