import React from "react";

class ProgressBar extends React.Component {

    render(){
        return (
            <div id="progressBar">
                <div id="red" style={{width: this.props.progress}}></div>
            </div>
        )
    }
}

export default ProgressBar;